import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 消费组分页查询
 * @param params
 */
export function findConsumerGroupPage(params) {
    return request({
        url: envInfo.bgApp.thingsPath + '/consumerGroup/page',
        method: 'get',
        params
    })
}

/**
 * 消费组列表查询
 * @param params
 */
export function findConsumerGroupList(params) {
    return request({
        url: envInfo.bgApp.thingsPath + '/consumerGroup/list',
        method: 'get',
        params
    })
}

/**
 * 创建消费组
 * @param params
 */
export function createConsumerGroup(params) {
    return request({
        url: envInfo.bgApp.thingsPath + '/consumerGroup/create',
        method: 'post',
        data: params
    })
}

/**
 * 删除消费组
 * @param params
 */
export function deleteConsumerGroup(params) {
    return request({
        url: envInfo.bgApp.thingsPath + '/consumerGroup/delete/'+params.consumerGroupId,
        method: 'delete'
    })
}


/**
 * 更新消费组
 * @param params
 */
export function updateConsumerGroup(params) {
    return request({
        url: envInfo.bgApp.thingsPath + '/consumerGroup/update',
        method: 'post',
        data: params
    })
}