<template>
  <div class="app-container consumer-group-list">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createConsumer">添加消费组</el-button>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>

    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>

    <el-dialog
      :visible.sync="consumerGroup.addDialogVisible"
      :before-close="cancelConsumerGroupSave"
      width="30%"
      :close-on-click-modal="false"
    >
      <template slot="title">
        {{ consumerGroup.title }}
      </template>
      <el-form
        v-loading="createLoading"
        label-position="top"
        :model="consumerGroup.form"
        ref="consumerGroupeForm"
        :rules="consumerGroup.formRules"
      >
        <el-form-item
          :label="$t('vlink.consumerGroup.consumerGroupName')"
          prop="consumerGroupName"
        >
          <el-input
            :disabled="consumerGroup.isEdit"
            v-model="consumerGroup.form.consumerGroupName"
            maxlength="32"
            :show-word-limit="true"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('vlink.tsl.description')" prop="description">
          <el-input
            v-model="consumerGroup.form.description"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancelConsumerGroupSave">{{
          $t("commons.cancel")
        }}</el-button>
        <el-button type="primary" @click="handleConsumerGroupSave">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  findConsumerGroupPage,
  createConsumerGroup,
  deleteConsumerGroup,
  updateConsumerGroup,
} from "@/api/ruge/vlink/consumerGroup/consumerGroup";
export default {
  name: "consumerGroupList",
  components: {
    finalTable,
  },
  data() {
    return {
      loadingFlag: false,
      createLoading: false,
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          {
            prop: "consumerGroupKey",
            label: this.$t("vlink.consumerGroup.consumerGroupKey"),
            width: "",
          },
          {
            prop: "consumerGroupName",
            label: this.$t("vlink.consumerGroup.consumerGroupName"),
            width: "",
          },
          {
            prop: "consumerGroupSecret",
            label: this.$t("vlink.consumerGroup.consumerGroupSecret"),
            width: "",
          },
          {
            prop: "description",
            label: this.$t("vlink.tsl.description"),
            width: "",
          },
          {
            prop: "creationDate",
            label: this.$t("commons.creationTime"),
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "115",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          consumerGroupName: {
            type: "input",
            label: "消费组名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入消费组名称",
            prefixIcon: "el-icon-search",
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          creationDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          consumerGroupName: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      consumerGroup: {
        title: null,
        isEdit: false,
        total: 0,
        list: null,
        listLoading: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          consumerGroupName: null,
        },

        addDialogVisible: false,
        form: {
          consumerGroupId: null,
          consumerGroupKey: null,
          consumerGroupName: null,
          consumerGroupProtocol: "amqp",
          description: null,
        },
        formRules: {
          consumerGroupName: [
            {
              required: true,
              trigger: ["blur", "change"],
              message: this.$t("validate.required"),
            },
            {
              min: 4,
              message: this.$t("vlink.device.deviceNameRule1"),
              trigger: ["blur"],
            },
            {
              pattern: /^[A-Za-z0-9-@#()]+$/,
              message: this.$t("vlink.device.deviceNameRule2"),
              trigger: ["blur", "change"],
            },
          ],
        },
      },
    };
  },
  watch: {
    loadingFlag(val) {
      this.$refs.finalTableRef.loadingToggle(val);
    },
  },
  created() {
    this.findConsumerGroupPage();
  },
  methods: {
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.findConsumerGroupPage();
    },
    findConsumerGroupPage() {
      let param = this.consumerGroup.listQuery;
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.loadingFlag = true;
      findConsumerGroupPage(param).then((response) => {
        this.dataset.pageVO.total = response.total;
        this.dataset.tableData = response.rows;
        this.loadingFlag = false;
      });
    },
    createConsumer() {
      this.consumerGroup.title = this.$t("commons.add");
      this.consumerGroup.isEdit = false;
      this.consumerGroup.form = {
        consumerGroupId: null,
        consumerGroupKey: null,
        consumerGroupName: null,
        consumerGroupProtocol: "amqp",
        description: null,
      };
      this.consumerGroup.addDialogVisible = true;
    },
    editConsumerGroup(row) {
      this.consumerGroup.title = this.$t("commons.edit");
      this.consumerGroup.isEdit = true;
      this.consumerGroup.form = row;
      this.consumerGroup.addDialogVisible = true;
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.consumerGroup.listQuery = {
          ...this.consumerGroup.listQuery,
          ...datas.params,
        };
        this.dataset.pageVO.current = 1;
        this.findConsumerGroupPage();
      } else if (datas.type === "paginationChange") {
        this.dataset.pageVO.current = datas.params.current.page;
        this.dataset.pageVO.rowCount = datas.params.current.limit;
        this.findConsumerGroupPage();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "edit":
            this.editConsumerGroup(datas.row);
            break;
          case "delete":
            this.deleteConsumerGroup(datas.row.consumerGroupId);
            break;
        }
      }
    },
    deleteConsumerGroup(consumerGroupId) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteConsumerGroup({ consumerGroupId: consumerGroupId }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.findConsumerGroupPage();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    cancelConsumerGroupSave() {
      this.consumerGroup.addDialogVisible = false;
    },
    handleConsumerGroupSave() {
      this.$refs.consumerGroupeForm.validate((v) => {
        if (v) {
          this.createLoading = true;
          if (this.consumerGroup.form.consumerGroupId == null) {
            createConsumerGroup(this.consumerGroup.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.consumerGroup.addDialogVisible = false;
                this.findConsumerGroupPage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.createLoading = false;
              });
          } else {
            updateConsumerGroup(this.consumerGroup.form)
              .then(() => {
                // 成功提示
                this.$message({
                  message: this.$t("message.saveSuccess"),
                  type: "success",
                });
                this.consumerGroup.addDialogVisible = false;
                this.findConsumerGroupPage();
              })
              .catch((error) => {
                console.log(`保存失败，原因 => ${error}`);
              })
              .finally(() => {
                this.createLoading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.consumer-group-list {
  padding: 32px 24px;
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>